import React, { useContext } from 'react';
import { DataContext } from '../context/DataContext';

export default function Profile() {
  const data = useContext(DataContext);

  const experiences = data.experiences.map((exp) => {
    const { title, company, location, dates, responsibilities } = exp;

    const resps = responsibilities.map((resp, index) => {
      return <li key={index}>{resp}</li>;
    });

    return (
      <div className="row">
        <h6>
          <b>
            {title} at <i>{company}</i> &nbsp;
          </b>
          <i>({location})</i>
        </h6>
        <h6>{dates}</h6>
        <ul>{resps}</ul>
      </div>
    );
  });

  return (
    <div className="container">
      <div className="row">
        <h5>
          <b>Professional Experience</b>
        </h5>
        {experiences}
      </div>
    </div>
  );
}
