import { DataProvider } from './context/DataContext';
import './app.css';
import Header from './components/Header';
import Profile from './components/Profile';
import Skills from './components/Skills';
import Experiences from './components/Experiences';
import Educations from './components/Educations';
import Certifications from './components/Certifications';
import Languages from './components/Languages';

function App() {
  return (
    <DataProvider>
      <Header />
      <Profile />
      <Skills />
      <Experiences />
      <Educations />
      <Certifications />
      <Languages />
    </DataProvider>
  );
}

export default App;
