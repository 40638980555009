import React, { useContext } from 'react';
import { DataContext } from '../context/DataContext';

export default function Certifications() {
  const data = useContext(DataContext);

  const certifications = data.certifications.map((cert) => {
    const { name, link, certification_number } = cert;
    return (
      <li key={name}>
        {name} <a href={link}>{certification_number}</a>
      </li>
    );
  });

  return (
    <div className="container">
      <div className="row">
        <h5>
          <b>Certifications</b>
        </h5>
        <ul>{certifications}</ul>
      </div>
    </div>
  );
}
