import React, { useContext } from 'react';
import { DataContext } from '../context/DataContext';

export default function Profile() {
  const data = useContext(DataContext);

  const skills = data.skills.map((skill) => {
    const skillTitle = Object.keys(skill)[0];
    const skillContents = skill[skillTitle].join(', ');
    return (
      <li key={skillTitle}>
        <b>{skillTitle}</b>: {skillContents}
      </li>
    );
  });

  return (
    <div className="container">
      <div className="row">
        <h5>
          <b>Skills</b>
        </h5>
        <ul>{skills}</ul>
      </div>
    </div>
  );
}
