import React, { useContext } from 'react';
import { DataContext } from '../context/DataContext';

export default function Profile() {
  const data = useContext(DataContext);

  return (
    <div className="container">
      <div className="row">
        <h5>
          <b>Profile</b>
        </h5>
        {data.profile}
      </div>
    </div>
  );
}
