import React, { useContext } from 'react';
import { DataContext } from '../context/DataContext';

const formatPhoneNumber = (s) => {
  var s2 = ('' + s).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : '+1 (' + m[1] + ') ' + m[2] + '-' + m[3];
};

export default function Header() {
  const data = useContext(DataContext);
  const marginZero = { margin: '0px' };
  const marginFive = { margin: '5px' };

  return (
    <div className="container center-align">
      <div className="row" style={{ marginTop: '100px', marginBottom: '0px' }}>
        <h4 style={marginZero}>
          <b>{data.name}</b>
        </h4>
      </div>
      <div className="row" style={marginFive}>
        <h5 style={marginZero}>
          <b>{data.title}</b>
        </h5>
      </div>
      <div className="row" style={marginFive}>
        <h6 style={marginZero}>{data.location}</h6>
      </div>
      <div className="row" style={marginFive}>
        <div className="col s12 m4 l4">
          <a href={`tel:1${data.phone}`}>{formatPhoneNumber(data.phone)}</a>
        </div>
        <div className="col s12 m4 l4">
          <a href={`mailto:${data.email}`}>{data.email}</a>
        </div>
        <div className="col s12 m4 l4">
          <a href={data.linkedin}>Linkedin</a>
        </div>
      </div>
      <hr style={{ marginBottom: '20px' }} />
    </div>
  );
}
