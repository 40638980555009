import React, { useContext } from 'react';
import { DataContext } from '../context/DataContext';

export default function Educations() {
  const data = useContext(DataContext);

  const educations = data.education.map((education, index) => {
    const { degree, field, GPA, institution, location, dates, publication } = education;

    return (
      <>
        <div className="row" style={{ margin: '0px' }}>
          <div className="col s12 m3">
            <i>
              <b>{institution}</b>
            </i>
            <br />({location})
          </div>
          <div className="col s12 m6">
            {degree}, {field} {GPA ? `(GPA: ${GPA})` : ''}
          </div>

          <div className="col s12 m3">{dates}</div>
        </div>
        {publication ? (
          <div className="row" style={{ margin: '0px' }}>
            <div className="col s12 m6 l6 offset-m3 offset-l3">
              Poster:{' '}
              <a className="poster_link" href={publication.poster_link}>
                {publication.poster}
              </a>
            </div>
          </div>
        ) : (
          ''
        )}
        {index === data.education.length - 1 ? '' : <hr />}
      </>
    );
  });

  return (
    <div className="container">
      <div className="row">
        <h5>
          <b>Education</b>
        </h5>
        {educations}
      </div>
    </div>
  );
}
