import React, { useContext } from 'react';
import { DataContext } from '../context/DataContext';

export default function Languages() {
  const data = useContext(DataContext);

  const languages = data.languages.map((lang) => {
    const { language, proficiency } = lang;
    return (
      <li key={language}>
        <b>{language}</b>: {proficiency}
      </li>
    );
  });

  return (
    <div className="container">
      <div className="row">
        <h5>
          <b>Languages</b>
        </h5>
        <ul>{languages}</ul>
      </div>
    </div>
  );
}
